import React from "react";

import "./Navbar.css";

const navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light NavStyle">
                <p className="navbar-brand">AdminPanel</p>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <p className="nav-link NavItem">Home</p>
                        </li>
                        <li className="nav-item active">
                            <a
                                className="nav-link NavItem"
                                href="https://sam.moramma.com"
                                target="_blank"
                            >
                                SAM
                            </a>
                        </li>
                        <li className="nav-item active">
                            <p className="nav-link NavItem">Grafana</p>
                        </li>
                        <li className="nav-item active">
                            <p className="nav-link NavItem">Timber</p>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default navbar;
