import React from "react";

import "./SpotStatusItem.css";

const SpotStatusItem = (props) => {
    return (
        <>
            <div className="spot-container">
                <div className="row">
                    <div className="col-1 align-self-center">
                        <p className="Type">{props.ep}</p>
                    </div>
                    <div className="col align-self-center">
                        <p className="Name">
                            <strong>{props.spotName}</strong>
                        </p>
                    </div>
                    <div className="col align-self-center">
                        <p className="Name">
                            lat.
                            {props.spotLat.toString().substr(0, 5)} {" / "}lng.{" "}
                            {props.spotLng.toString().substr(0, 5)}
                        </p>
                    </div>
                    <div className="col align-self-center">
                        <p className="Name">Type: {props.spotType}</p>
                    </div>
                    {/**onClick={() => props.samHandler(props.idx) */}
                    <div className="col-1 align-self-center">
                        <a
                            href={`https://sam.moramma.com?token=${props.token}&spotName=${props.spotName}`}
                            target="_blank"
                        >
                            <p className="Name">
                                <i className="fas fa-user-shield"> </i>
                            </p>
                        </a>
                    </div>
                    <div className="col-1" />
                </div>
            </div>
        </>
    );
};

export default SpotStatusItem;
