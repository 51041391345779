import React from "react";
import { Route } from "react-router-dom";

import Dispatcher from "./../../containers/Dispatcher/Dispatcher";
import Login from "../../containers/Login/Login";

const layout = () => (
    <>
        <Route path="/" exact component={Login} />
        <Route path="/dispatcher" exact component={Dispatcher} />
    </>
);

export default layout;
