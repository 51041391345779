import React, { Component } from "react";

import logger from "./../../utils/logger";

import "./ApiStatus.css";
import axios from "axios";

import IcRefresh from "./../../icons/ic_reload.svg";
import IcExpandMore from "./../../icons/ic_expand_more.svg";
import IcExpandLess from "./../../icons/ic_expand_less.svg";
import IcServer from "./../../icons/ic_server.svg";

class ApiStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            betaStatus: false,
            betaStatusLastFetch: "",
            betaStatusExpand: false,
            stabbleStatus: false,
            stableStatusLastFetch: "",
            stableStatusExpand: false,
        };
    }

    async componentDidMount() {
        await this.GET_ApiBetaStatus();
        await this.GET_ApiStableStatus();
    }

    GET_ApiBetaStatus = async () => {
        // Call
        await axios({
            method: "get",
            url: "https://beta.moramma.com",
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        betaStatus: true,
                        betaStatusLastFetch: new Date(),
                    });
                } else {
                    this.setState({
                        betaStatus: false,
                        betaStatusLastFetch: new Date(),
                    });
                }
            })
            .catch((err) => {
                logger.debug("[admin|Login.js|POST_authenticate] Error:", err);
                this.setState({
                    betaStatus: false,
                    betaStatusLastFetch: new Date(),
                });
                /*if (err.response.status) {
                    this.setState({ labelError: "Unauthorized." });
                } else {
                    this.setState({ labelError: "Error during login." });
                }*/
            });
    };

    GET_ApiStableStatus = async () => {
        // Call
        await axios({
            method: "get",
            url: "https://api.moramma.com",
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        stableStatus: true,
                        stableStatusLastFetch: new Date(),
                    });
                } else {
                    this.setState({
                        stableStatus: false,
                        stableStatusLastFetch: new Date(),
                    });
                }
            })
            .catch((err) => {
                logger.debug("[admin|Login.js|POST_authenticate] Error:", err);
                this.setState({
                    stableStatus: false,
                    stableStatusLastFetch: new Date(),
                });
                /*if (err.response.status) {
                    this.setState({ labelError: "Unauthorized." });
                } else {
                    this.setState({ labelError: "Error during login." });
                }*/
            });
    };

    expandHandlerBeta = () => {
        const { betaStatusExpand } = this.state;
        this.setState({ betaStatusExpand: !betaStatusExpand });
    };

    expandHandlerStable = () => {
        const { stableStatusExpand } = this.state;
        this.setState({ stableStatusExpand: !stableStatusExpand });
    };

    render() {
        const {
            stableStatus,
            stableStatusLastFetch,
            stableStatusExpand,
            betaStatus,
            betaStatusLastFetch,
            betaStatusExpand,
        } = this.state;
        return (
            <>
                <div className="container mt-50">
                    <h4>API Status</h4>
                    <div className="api-card">
                        <div className="row">
                            <div className="col-2 api-card-source align-self-center">
                                <p className="api-card-source-title">
                                    api.moramma.com
                                </p>
                            </div>
                            <div className="col-2 align-self-center">
                                {stableStatus ? (
                                    <p className="api-card-source-status-ok">
                                        --&gt; Status: UP
                                    </p>
                                ) : (
                                    <p className="api-card-source-status-err">
                                        --&gt; Status: DOWN
                                    </p>
                                )}
                            </div>
                            <div className="col align-self-center">
                                <p className="api-card-source-status-err">
                                    {stableStatusLastFetch
                                        ? stableStatusLastFetch.toGMTString()
                                        : ""}
                                </p>
                            </div>
                            <div className="col-1 align-self-center">
                                <img
                                    src={IcRefresh}
                                    alt="reload"
                                    onClick={this.GET_ApiStableStatus}
                                />
                            </div>
                            {stableStatusExpand ? (
                                <div className="col-1 align-self-center">
                                    <img
                                        src={IcExpandMore}
                                        alt="reload"
                                        onClick={this.expandHandlerStable}
                                    />
                                </div>
                            ) : (
                                <div className="col-1 align-self-center">
                                    <img
                                        src={IcExpandLess}
                                        alt="reload"
                                        onClick={this.expandHandlerStable}
                                    />
                                </div>
                            )}
                        </div>
                        {stableStatusExpand ? (
                            <div className="api-card-list">
                                <img
                                    src={IcServer}
                                    className="api-card-list-img"
                                    alt="ic-server"
                                />
                                <p className="api-card-list-txt">
                                    <a href="#">View LOG source api.moramma</a>
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="api-card">
                        <div className="row">
                            <div className="col-2 api-card-source align-self-center">
                                <p className="api-card-source-title">
                                    beta.moramma.com
                                </p>
                            </div>
                            <div className="col-2 align-self-center">
                                {betaStatus ? (
                                    <p className="api-card-source-status-ok">
                                        --&gt; Status: UP
                                    </p>
                                ) : (
                                    <p className="api-card-source-status-err">
                                        --&gt; Status: DOWN
                                    </p>
                                )}
                            </div>
                            <div className="col align-self-center">
                                <p className="api-card-source-status-err">
                                    {betaStatusLastFetch
                                        ? betaStatusLastFetch.toGMTString()
                                        : ""}
                                </p>
                            </div>
                            <div className="col-1 align-self-center">
                                <img
                                    src={IcRefresh}
                                    alt="reload"
                                    onClick={this.GET_ApiBetaStatus}
                                />
                            </div>
                            {betaStatusExpand ? (
                                <div className="col-1 align-self-center">
                                    <img
                                        src={IcExpandMore}
                                        alt="reload"
                                        onClick={this.expandHandlerBeta}
                                    />
                                </div>
                            ) : (
                                <div className="col-1 align-self-center">
                                    <img
                                        src={IcExpandLess}
                                        alt="reload"
                                        onClick={this.expandHandlerBeta}
                                    />
                                </div>
                            )}
                        </div>
                        {betaStatusExpand ? (
                            <div className="api-card-list">
                                <img
                                    src={IcServer}
                                    className="api-card-list-img"
                                    alt="ic-server"
                                />
                                <p className="api-card-list-txt">
                                    <a href="#">View LOG source beta.moramma</a>
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ApiStatus;
