import React, { Component } from "react";
import ReactLoading from "react-loading";

import "./SpotStatus.css";
import axios from "axios";
import SpotStatusItem from "./SpotStatusItem/SpotStatusItem";

class SpotStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            betaSpots: [],
            renderedBetaSpots: [],
            stableSpots: [],
            renderedStableSpots: [],
        };
    }

    async componentDidMount() {
        //await this.test();
        await this.GET_betaSpotsList();
        await this.GET_stableSpotsList();
    }

    SAM_getSpotData = async (idx) => {
        //("called");
        const { betaSpots, stableSpots } = this.state;
        let uniqueSpots = betaSpots;
        uniqueSpots = uniqueSpots.concat(stableSpots);

        const spot = uniqueSpots[idx];
        const localtoken = localStorage.getItem("token");

        if (!localtoken) {
            console.error("no token");
            return;
        }

        //console.log(uniqueSpots, idx, spot);
        // Call
        await axios({
            method: "get",
            url: `https://beta.moramma.com/sam/spot?spotName=${spot.location.name}`,
            headers: {
                Authorization: `Bearer ${localtoken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    GET_betaSpotsList = async () => {
        // Call
        await axios({
            method: "get",
            url: "https://beta.moramma.com/openapi/location",
        })
            .then((res) => {
                if (res.data.data) {
                    this.setState({ betaSpots: res.data.data });
                    this.buildBetaSpots(res.data.data, "beta");
                    //console.log("data", res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    buildBetaSpots = (spots, ep) => {
        const localtoken = localStorage.getItem("token");
        const render = spots.map((spot, idx) => {
            //console.log(spot);
            return (
                <SpotStatusItem
                    key={idx}
                    idx={idx}
                    ep={ep}
                    spotName={spot.location.name}
                    spotType={spot.location.spotType}
                    spotLat={spot.location.lat}
                    spotLng={spot.location.lng}
                    samHandler={this.SAM_getSpotData}
                    token={localtoken}
                />
            );
        });
        this.setState({ renderedBetaSpots: render });
    };

    GET_stableSpotsList = async () => {
        // Call
        await axios({
            method: "get",
            url: "https://api.moramma.com/openapi/location",
        })
            .then((res) => {
                if (res.data.data) {
                    this.setState({
                        stableSpots: res.data.data,
                    });
                    this.buildStableSpots(res.data.data, "api");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    buildStableSpots = (spots, ep) => {
        const localtoken = localStorage.getItem("token");
        const render = spots.map((spot, idx) => {
            //console.log(spot);
            return (
                <SpotStatusItem
                    key={idx}
                    idx={idx}
                    ep={ep}
                    spotName={spot.location.name}
                    spotType={spot.location.spotType}
                    spotLat={spot.location.lat}
                    spotLng={spot.location.lng}
                    samHandler={this.SAM_getSpotData}
                    token={localtoken}
                />
            );
        });
        this.setState({ renderedStableSpots: render });
    };

    render() {
        const { renderedBetaSpots, renderedStableSpots } = this.state;
        return (
            <>
                <div className="container mt-50">
                    <h4>Spots</h4>
                    {renderedBetaSpots}
                    {renderedStableSpots.length >= 1 ? (
                        renderedStableSpots
                    ) : (
                        <div className="TxtCenter d-flex d-flex-wrap content-center justify-content-center">
                            <ReactLoading
                                type={"bubbles"}
                                color={"#336FD1"}
                                height={55}
                                width={55}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default SpotStatus;
