import React, { Component } from "react";

import logger from "./../../utils/logger";
import axios from "axios";
import ENDPOINT from "./../../utils/ep.json";
import Layout from "./../../components/Layout/Layout";
import { Redirect } from "react-router";
import "./Login.css";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            key: "",
            labelError: "",
            redirect: false,
        };
    }

    POST_authenticate = async () => {
        logger.debug("[admin|Login.js|POST_authenticate] begin");

        const { username, key } = this.state;

        if (username === "" || key === "") {
            return;
        }

        // Call
        await axios({
            method: "post",
            url: `${ENDPOINT.BE}/auth/admin`,
            data: { user: username, key: key },
        })
            .then(async (res) => {
                if (res.data.data) {
                    logger.debug(
                        "[admin|Login.js|POST_authenticate] Request Ok"
                    );
                    localStorage.setItem("token", res.data.data.token);
                    this.setState({ redirect: true });
                } else {
                    logger.debug(
                        "[admin|Login.js|POST_authenticate] Request error"
                    );
                    this.setState({ labelError: "Please retry." });
                }
            })
            .catch((err) => {
                logger.debug("[admin|Login.js|POST_authenticate] Error:", err);
                if (err.response.status) {
                    this.setState({ labelError: "Unauthorized." });
                } else {
                    this.setState({ labelError: "Error during login." });
                }
            });
    };

    updateUsername = (event) => {
        this.setState({ username: event.target.value });
    };

    updateKey = (event) => {
        this.setState({ key: event.target.value });
    };

    render() {
        const { labelError, redirect } = this.state;

        if (redirect) {
            return <Redirect to="/dispatcher" />;
        }

        return (
            <>
                <div className="container BgColor h-100 mt-100">
                    <h2 className="MainText"> Authentication</h2>
                    <div className="form-group">
                        <label className="MainText">User id</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="id"
                            value={this.state.username}
                            onChange={this.updateUsername}
                        />
                    </div>
                    <div className="form-group">
                        <label className="MainText">Key</label>
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="key"
                            value={this.state.key}
                            onChange={this.updateKey}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.POST_authenticate}
                    >
                        Auth
                    </button>
                </div>

                {labelError === "" ? (
                    <p></p>
                ) : (
                    <p className="ErrorLabel">{labelError}</p>
                )}
            </>
        );
    }
}

export default Login;
