import React, { Component } from "react";

import "./Dispatcher.css";
import Navbar from "../../components/Static/Navbar";
import ApiStatus from "../ApiStatus/ApiStatus";
import SpotStatus from "../SpotsStatus/SpotStatus";

class Dispatcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
        };
    }

    render() {
        return (
            <>
                <Navbar />
                <ApiStatus />
                <SpotStatus />
            </>
        );
    }
}

export default Dispatcher;
